import app from 'firebase/app';

const config = {

    projectId: "onestep-9d777",

};
class Firebase {
    constructor() {
        app.initializeApp(config);
    }
}
export default Firebase;
