import * as React from "react";
import {MDBContainer} from "mdbreact";
import NavBar from "../navBars/NavBar";

class JumbotronHeader extends React.Component {
    render() {
        return (
                <MDBContainer fluid={true} className={'header-img text-center'} >
                    <NavBar/>
                    <div className={'container'}  style={{"padding": "200px 0px"}}>
                    <h1  className={'big-letters'} style={{"color":"white"}}>An Opportunity</h1>
                    <h1 className={'big-letters'} style={{"color":"white"}}>to Serve Is Just</h1>
                    <h1 className={'big-letters'} style={{"color":"#aa854b", fontFamily: "OrchardCircularBlock"}}>O&nbsp;N&nbsp;E&nbsp;S&nbsp;T&nbsp;E&nbsp;P</h1>
                    <h1 className={'big-letters'} style={{"color":"white"}}>Away</h1>
                    </div>

                </MDBContainer>
        );
    }
}

export default JumbotronHeader;