import * as React from "react";
import {bold} from '../common/Helpers';
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import bookCover from "../resources/images/OneStepBook.png";
import {NavLink} from "react-router-dom";
import JumbotronHeader from "../components/headers/JumbotronHeader";

class LandingPage extends React.Component {
    render() {
        return (
            <div>
            <JumbotronHeader/>
            <MDBContainer fluid={true} className="container-fluid gold-drop-background container-fluid-content">
                <MDBRow middle={true} center={true}>
                    <MDBCol md={'5'}>
                        <MDBRow middle={true} center={true}>
                            <MDBCol middle={true} >
                                <img className={'bookCoverImg'} src={bookCover}/>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow middle={true} center={true}>
                            <MDBCol middle={true}>
                                <div className={'text-center'} >
                                    <NavLink className={'button'} style={{"color": "white", fontFamily:"OrchardCircularBlock", fontWeight:"bold"}} to={'/get-started'}>Get Started</NavLink>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={'6'} className="text-left" style={{ paddingTop: "80px"}}>
                        <MDBRow middle={true} className={'bookCover'}>
                            <div className={'text-container'}>
                                <p>
                                    Jesus was clear. A big part of following Him is serving other people. But what does
                                    that
                                    look like and how can we do it better?
                                </p>

                                <p>
                                    {bold('OneStep')} was created to help Jesus’s followers do just
                                    that! {bold('OneStep')} is
                                    an easy to use resource designed for small groups,
                                    Sunday school classes, or any kind of discussion-based gathering of people seeking
                                    to follow Jesus more faithfully. The goal of this study is for every participant to
                                    actually take one tangible step to more faithfully live out serving like Jesus did.
                                </p>

                                <p>
                                    This resource is completely free to use and has been designed by the Orchard
                                    Institute, a ministry of Orchard. Orchard is a non-profit with nearly over two
                                    decades of inspiring and equipping Christians to serve more faithfully in their
                                    communities.
                                </p>
                            </div>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            </div>
        )
            ;
    }
}

export default LandingPage;