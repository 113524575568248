import * as React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import AltHeader from "../components/headers/AltHeader";
import { bold } from "../common/Helpers";
import AltHeaderCity from "../components/headers/AltHeaderCity";
// import css
// @ts-ignore


class ForPastors extends React.Component {
    render() {
        return (
            <div>
                <AltHeaderCity />
                <div className="container-fluid gold-church-background">
                    <MDBRow center={true} >
                        <h1 className="title big-fat-letters">Orchard Churches</h1>
                    </MDBRow>
                    <MDBRow middle={true} center={true}>
                        <MDBCol md={'6'} className={'text-left'}>
                            <p>{bold('One Step')} was designed by Orchard to give church leaders a practical tool to
                                get people serving in the community. We love the local church and are thrilled to offer
                                this resource completely free of charge.
                            </p>
                            <p>
                                If you want to further explore the resources we have available for churches, we'd love
                                to
                                talk to you about our Orchard Churches program.
                            </p>
                            <p>
                                If you'd like to start the conversation, just fill out the form below and a member of
                                our
                                team will be in touch with you!
                            </p>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow middle={true} center={true}>
                        <iframe src="https://services.cognitoforms.com/f/jRFvHZ-zz02LIGqs7EjKPg?id=33"
                            style={{ position: "relative", minWidth: "100%*", width: "100%", color: "white" }}
                            frameBorder="0"
                            scrolling="yes" height="1100" width="100%" />
                        <script src="https://services.cognitoforms.com/scripts/embed.js" />
                    </MDBRow>
                </div>
            </div>

        );
    }
}

export default ForPastors;