import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from "./App";
import {Route} from 'react-router';
import {BrowserRouter} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'video-react/dist/video-react.css';
import BehindTheStudyPage from "./pages/BehindTheStudyPage";
import GetStarted from "./pages/GetStarted";
import JumbotronFooter from "./components/footers/JumbotronFooter";
import image1 from "../resources/images/chalk-gold-background.jpg"
import Contact from "./pages/Contact";
import ForPastors from "./pages/ForPastors";
import Firebase, { FirebaseContext } from './components/Firebase';

const Routes = () => (
    //@ts-ignore
    <FirebaseContext.Provider value={new Firebase()}>
    <BrowserRouter>
        <div>
            <Route exact={true} path="/" component={App}/>
            <Route exact={true} path="/landing" component={LandingPage}/>
            <Route exact={true} path="/behind" component={BehindTheStudyPage}/>
            <Route exact={true} path="/get-started" component={GetStarted}/>
            <Route exact={true} path="/contact" component={Contact}/>
            <Route exact={true} path="/for-pastors" component={ForPastors}/>
        </div>
        <JumbotronFooter/>
    </BrowserRouter>
    </FirebaseContext.Provider>
);

ReactDOM.render(<Routes/>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
