import * as React from "react";
import {NavLink} from "react-router-dom";
import {MDBCol, MDBRow} from "mdbreact";
import AltHeader from "../components/headers/AltHeader";
import JumbotronFooter from "../components/footers/JumbotronFooter";
import AltHeaderWalk from "../components/headers/AltHeaderWalk";

// import css
// @ts-ignore


class GetStarted extends React.Component {
    render() {
        return (
            <div>
                <AltHeaderWalk/>
                <div className="container-fluid gold-drop-background container-fluid-content">
                    <div className="container container-custom text-center ">
                        <MDBRow middle={true} center={true}>
                            <MDBCol md={'6'}>
                                <p className={'text-center'}>
                                    Ready to take get started? Download the leader and study
                                    guides, gather your group, and watch the video below!
                                </p>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow middle={true} center={true}>
                            <iframe src="https://player.vimeo.com/video/367110197" width="700" height="400"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen>

                            </iframe>
                        </MDBRow>
                        <MDBRow middle={true} center={true}>
                            <a
                                className={'button'}
                                href={'/One%20Step%20Study%20Guide%20.pdf'}
                                target={'_blank'}
                                style={{"color": "white", fontFamily: "OrchardCircularBlock", fontWeight: "bold"}}
                                download={true}>
                                Download Study Guide
                            </a>
                            <a className={'button'}
                               href={'/One%20Step%20Leader\'s%20Guide.pdf'}
                               style={{"color": "white", fontFamily: "OrchardCircularBlock", fontWeight: "bold"}}
                               download={true}>
                                Download Leaders Guide
                            </a>
                        </MDBRow>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetStarted;