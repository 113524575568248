import * as React from "react";
import {MDBContainer} from "mdbreact";
import NavBar from "../navBars/NavBar";

class AltHeaderChalk extends React.Component {
    render() {
        return (
            <MDBContainer fluid={true} className={'container-fluid header-img-alt-chalk text-center'} >
                <NavBar/>

            </MDBContainer>
        );
    }
}

export default AltHeaderChalk;