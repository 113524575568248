import * as React from "react";
import {MDBNavLink} from "mdbreact";
import logo from "../../resources/images/Orch_OneStep_Webpages_Logos_NoText-07.png";
class JumbotronFooter extends React.Component {
    render() {
        return (
            <div>
                <footer className="global">
                    <div className="columns">
                        <div className="column">
                            <p>
                                <a href="mailto:institute@meetorchard.org" className="cta email">Email</a>
                                <a target="_blank"
                                   href="https://www.facebook.com/meetorchard/"
                                   className="cta facebook">Facebook</a>
                                <a
                                    target="_blank" href="https://www.instagram.com/meetorchard/"
                                    className="cta instagram">Instagram
                                </a>
                            </p>
                            <ul className="nav">
                                <MDBNavLink className={'text-color-light text-center'}
                                            to="/landing">{'Home'}</MDBNavLink>
                                <MDBNavLink className={'text-color-light text-center'} to="/behind">Behind the
                                    Study</MDBNavLink>
                                <MDBNavLink className={'text-color-light text-center'} to="/get-started">Get
                                    Started</MDBNavLink>
                                <MDBNavLink className={'text-color-light text-center'} to="/for-pastors">For
                                    Pastors</MDBNavLink>
                                <MDBNavLink className={'text-color-light text-center'}
                                            to="/contact">Contact</MDBNavLink>
                            </ul>
                        </div>
                        <div className="column">
                            <a href="https://www.meetorchard.org" className="title">
                                <img className={'box-img'} alt={"Loading Image"} src={logo}/>
                            </a>
                        </div>
                        <div className="column">
                            <p><a href="tel:+14048410800">404.841.0800</a><br/><a
                                href="https://goo.gl/maps/YgJhjCM2mz92"
                                target="_blank"
                                style={{color: "white"}}>3525 Piedmont Rd<br/>Bldg
                                5, suite 705<br/>Atlanta, GA 30305</a></p>
                        </div>
                    </div>
                    <p>© 2019 Orchard Resources, Inc. All rights reserved.</p>
                </footer>

            </div>
        );
    }
}

export default JumbotronFooter;