import * as React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import AltHeader from "../components/headers/AltHeader";
import Orch_Logo_1 from "../resources/images/PartnersLogo_1.png";
import Orch_Logo_2 from "../resources/images/PartnersLogo_2.png";
import Orch_Logo_3 from "../resources/images/PartnersLogo_3.png";
import { NavLink } from "react-router-dom";
import AltHeaderChalk from "../components/headers/AltHeaderChalk";
import { bold } from "../common/Helpers";

class BehindTheStudyPage extends React.Component {
    render() {
        return (
            <div>
                <AltHeaderChalk />
                <div className="container-fluid gold-drop-background">
                    <MDBRow center={true} middle={true}>
                        <h1 className="title big-fat-letters">Behind the Study</h1>
                    </MDBRow>
                    <MDBRow center={true} middle={true}>
                        <MDBCol md={'6'} middle={true}>
                            <div className="text-left">
                                <p>
                                    Imagine a world where Christ is known because of the actions of His followers!
                                </p>

                                <p>
                                    {bold('One Step')} was birthed out of this vision. As culture changes, one thing never will: every person <i>needs</i> Jesus. But how will the generations come to experience His incredible love in a society that is increasingly skeptical of religious institutions?
                                </p>

                                <p>
                                    If this shift in culture means it's less likely that new people will come to our churches, we must take that love to them. This world must become a place where Jesus is known because of the actions of His followers.
                                </p>
                                <p>
                                    Orchard exists to make this vision a reality. We inspire and equip people to demonstrate the love of Jesus in their communities through partnerships, training, and resources that empower them to use their gifts and passions for good.
                                </p>

                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow center={true} middle={true}>
                        <MDBCol md={'3'}><img className={'box-img'} src={Orch_Logo_1} /></MDBCol>
                        <MDBCol md={'3'}><img className={'box-img'} src={Orch_Logo_2} /></MDBCol>
                        <MDBCol md={'3'}><img className={'box-img'} src={Orch_Logo_3} /></MDBCol>
                    </MDBRow>
                    <MDBRow center={true} middle={true}>
                        <p>
                            To learn more about Orchard click <a style={{ color: "#09d3ac" }} href={'https://meetorchard.org'} >here</a>
                        </p>
                    </MDBRow>
                </div>
            </div>
        );
    }
}

export default BehindTheStudyPage;