import * as React from "react";
import {MDBContainer} from "mdbreact";
import NavBar from "../navBars/NavBar";

class AltHeaderWalk extends React.Component {
    render() {
        return (
            <MDBContainer fluid={true} className={'container-fluid header-img-alt-walk text-center'} >
            <NavBar/>

            </MDBContainer>
    );
    }
}

export default AltHeaderWalk;