import * as React from "react";
import {Component} from "react";
import {
    MDBCollapse,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBNavItem,
    MDBNavLink,
} from "mdbreact";
import logo from "../../resources/images/OneStepStudyCover(with-tagline).png"

type NavBarState = {
    isOpen?: boolean;
    homeActive?: boolean;
    behindActive?: boolean;
    getInTouchActive?: boolean;
    getStartedActive?: boolean;
    forThePastorActive?: boolean;
}

const initialState = {
    isOpen: false,
    homeActive: true,
    behindActive: false,
    getInTouchActive: false,
    forThePastorActive: false,
    getStartedActive: false
};

class NavBar extends Component<{}, NavBarState> {


    constructor(props: NavBarState) {
        super(props);
        this.state = initialState;
    }

    toggleCollapse = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    componentDidMount(): void {

    }

    render() {
        return (

            <MDBNavbar dark expand={'md'} style={{
                "position": "absolute",
                width: '100%',
                backgroundColor: "rgba(0,0,0,.9)",
                marginLeft: -15,
                padding: "0px 30px"
            }}>
                <MDBNavbarBrand>
                    <MDBNavLink to="/" active={true}>
                        <img className={'logo-img'} src={logo}/>
                    </MDBNavLink>
                </MDBNavbarBrand>

                <MDBNavbarToggler onClick={this.toggleCollapse}/>

                <MDBCollapse id="navbarCollapse3" navbar={true} isOpen={this.state.isOpen}>
                    <MDBNavbarNav right={true}>

                        <MDBNavItem>
                            <MDBNavLink className={'button-gold'} style={{
                                "color": "white",
                                fontFamily: "OrchardCircularBlock",
                                fontWeight: "bold"
                            }} to="/get-started" active={this.state.behindActive}>Get Started</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink className={'button-transparent'} style={{
                                "color": "white",
                                fontFamily: "OrchardCircularBlock",
                                fontWeight: "bold"
                            }} to="/behind" active={this.state.behindActive}>Behind The Study</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink className={'button-transparent'} style={{
                                "color": "white",
                                fontFamily: "OrchardCircularBlock",
                                fontWeight: "bold"
                            }} to="/contact" active={this.state.getInTouchActive}>Get In Touch</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink className={'button-transparent'} style={{
                                "color": "white",
                                fontFamily: "OrchardCircularBlock",
                                fontWeight: "bold"
                            }} to="for-pastors" active={this.state.getStartedActive}>For Pastors</MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        );
    }
}

export default NavBar;