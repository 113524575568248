import * as React from "react";
import {MDBCol, MDBRow} from "mdbreact";
import AltHeader from "../components/headers/AltHeader";
import JumbotronFooter from "../components/footers/JumbotronFooter";
// import css
// @ts-ignore


class Contact extends React.Component {
    render() {
        return (
            <div>
                <AltHeader/>
                <div className="container-fluid gold-drop-background container-fluid-content">

                        <MDBRow middle={true} center={true}>
                            <MDBCol md={'6'}>
                            <p className={'text-center'}>If you have any comments, question, or if you would like to share your declared step.
                                Please fill out the form provided below.</p>
                            </MDBCol>
                        </MDBRow>
                    <MDBRow middle={true} center={true}>
                            <iframe src="https://services.cognitoforms.com/f/jRFvHZ-zz02LIGqs7EjKPg?id=34"
                                    style={{position:"relative", minWidth:"100%*", width:"100%"}} frameBorder="0"
                                    scrolling="yes"  height="739" width="100%" />
                            <script src="https://services.cognitoforms.com/scripts/embed.js"/>
                        </MDBRow>
                    </div>
                </div>

        );
    }
}

export default Contact;